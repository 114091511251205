.f {
  display: flex;
}

.fv {
  display: flex;
  flex-direction: column;
}

.jb {
  justify-content: space-between;
}

.jc {
  justify-content: center;
}

.ic {
  align-items: center;
}

.popout1 {
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.3);
}

.popout2 {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.4);
}

.popout3 {
  box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.5);
}

.ca {
  justify-content: center;
  align-items: center;
}

.ch {
  justify-content: center;
}

.cv {
  align-items: center;
}

.tr1 {
  transition: all 150ms;
}

.tr2 {
  transition: all 300ms;
}

.tr3 {
  transition: all 600ms;
}

.rb {
  width: 4rem;
  height: 4rem;
  background: red;
  margin: 0 1rem;
}

.move {
  cursor: move;
}

.not-allowed {
  cursor: not-allowed;
}

.soft-shadow1 {
  box-shadow: rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
}

.usn {
  user-select: none;
}
